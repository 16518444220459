<template>
  <div class="public">
    <div class="container HomePolice">
      <!-- 四色码 -->
      <div class="tab-box">
        <el-radio-group v-model="riskarning" class="tab-btns">
          <el-radio-button
            :label="ind + 1"
            v-for="(val, ind) in riskarningList.slice(1)"
            >{{ val }}</el-radio-button
          >
        </el-radio-group>
      </div>
      <Toptab
        v-if="riskarning === 1"
        :topdata="forCodeData"
        :data="dataregion"
        class="high"
      />
      <Toptab
        v-if="riskarning === 2"
        :topdata="cerditStatistic"
        :data="dataregion"
        class="high"
        type="scroll"
      />
      <Toptab
        v-if="riskarning === 3"
        :topdata="fiveCodeData"
        :data="dataregion"
        class="high"
      />
      <!-- 风险预警区域分布情况 中国地图 -->
      <div class="idiot-two echarts pie-box">
        <div class="echarts-box">
          <div class="echarts-tit">
            <span>风险预警区域分布情况</span>
          </div>
        </div>
        <div class="map-charts">
          <Baidumap
            v-if="regionType >= 3 && areaName"
            v-loading="showbmap"
            element-loading-text="加载中..."
            element-loading-spinner="el-icon-loading"
            :regionName="regionName"
            :riskarning="riskarning"
            :flag="flag"
            :cityName="cityName"
            :areaName="areaName"
            :data="baidumapOptions"
          />
          <Pmap
            v-else
            v-loading="showmap"
            element-loading-text="加载中..."
            element-loading-spinner="el-icon-loading"
            :regionName="regionName"
            :cityName="cityName"
            :riskarning="riskarning"
            :flag="flag"
            :mapOptions="mapOptions"
          ></Pmap>
        </div>
      </div>
      <!-- 预付码新增变化趋势 折线图图 -->
      <div class="idiot-two echarts">
        <div class="echarts-box">
          <div class="echarts-tit">
            <span>风险商户新增变化趋势</span>
          </div>
          <div class="echarts-but">
            <KcSelect right="0" top="-35" @popupVal="popupVal"></KcSelect>
          </div>
        </div>
        <div class="charts">
          <LineChart
            v-loading="lineChart1"
            element-loading-text="加载中..."
            element-loading-spinner="el-icon-loading"
            v-if="riskarning === 1"
            :chartData="lineChartData1"
            :legendData="lineChartLegend1"
            :units="['家', '家', '家']"
            :type="radio1"
          ></LineChart>
          <LineChart
              v-if="riskarning === 2"
              v-loading="lineChart1"
              element-loading-text="加载中..."
              element-loading-spinner="el-icon-loading"
              :chartData="lineChartData1"
              :legendData="lineChartLegend2"
              :units="['家','家','家','家','家','家','家','家','家','家']"
              :type="radio1"
          ></LineChart>
          <LineChart
              v-if="riskarning === 3"
              v-loading="lineChart1"
              element-loading-text="加载中..."
              element-loading-spinner="el-icon-loading"
              :chartData="lineChartData1"
              :legendData="lineChartLegend3"
              :units="['家', '家', '家', '家', '家']"
              :type="radio1"
          ></LineChart>
        </div>
      </div>
      <!-- 预付码累计变化趋势 折线图图 -->
      <div class="idiot-two echarts">
        <div class="echarts-box">
          <div class="echarts-tit">
            <span>风险商户累计变化趋势</span>
          </div>
          <KcSelect right="0" top="-35" @popupVal="popupVal2"></KcSelect>
          <div class="echarts-but"></div>
        </div>

        <div class="charts">
          <LineChart
            v-loading="lineChart2"
            element-loading-text="加载中..."
            element-loading-spinner="el-icon-loading"
            v-if="riskarning === 1"
            :chartData="lineChartData2"
            :legendData="lineChartLegend1"
            :units="['家', '家', '家']"
          ></LineChart>
          <LineChart
              v-loading="lineChart2"
              element-loading-text="加载中..."
              element-loading-spinner="el-icon-loading"
              v-if="riskarning === 2"
              :chartData="lineChartData2"
              :legendData="lineChartLegend2"
              :units="['家','家','家','家','家','家','家','家','家','家']"
              :type="radio2"
          ></LineChart>
          <LineChart
              v-loading="lineChart2"
              element-loading-text="加载中..."
              element-loading-spinner="el-icon-loading"
              v-if="riskarning === 3"
              :chartData="lineChartData2"
              :legendData="lineChartLegend3"
              :units="['家', '家', '家', '家', '家']"
              :type="radio2"
          ></LineChart>
        </div>
      </div>
      <!-- 预付码按城市/地区统计 表格 -->
      <div class="idiot-two echarts">
        <div class="echarts-box">
          <div class="echarts-tit">
            <span>风险预警按城市/地区统计</span>
          </div>
          <KcSelect
            isBlue
            keyLabel="haripinType"
            :optionData="options"
            @popupVal="popupVal3"
          ></KcSelect>
        </div>
        <div class="element-tab">
          <el-table
            v-if="riskarning === 1"
            :data="tableDataAll"
            v-loading="loading1"
            element-loading-text="加载中..."
            element-loading-spinner="el-icon-loading"
            style="width: 100%"
            :row-key="rowKey"
            lazy
            :load="loadNextNode1"
            :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
            @sort-change="changeSort"
            :header-cell-style="{ textAlign: 'center' }"
          >
            <el-table-column
              prop="regionName"
              label="地区"
              :width="0.18 * $bodyWidth"
              fixed
            ></el-table-column>
            <el-table-column prop="num" label="总计" sortable>
              <template slot-scope="scope">
                <span class="num-box">{{ scope.row.num }}</span
                ><i
                  :class="
                    scope.row.numSign > 0
                      ? 'num-badge-blue'
                      : scope.row.numSign < 0
                      ? 'num-badge-orag'
                      : 'none'
                  "
                  >{{
                    scope.row.numSign > 0
                      ? "+" + scope.row.numSign
                      : scope.row.numSign < 0
                      ? "" + scope.row.numSign
                      : ""
                  }}</i
                >
              </template>
            </el-table-column>
            <el-table-column prop="lowNum" label="低风险" sortable>
              <template slot-scope="scope">
                <span class="num-box">{{ scope.row.lowNum }}</span
                ><i
                  :class="
                    scope.row.lowSign > 0
                      ? 'num-badge-blue'
                      : scope.row.lowSign < 0
                      ? 'num-badge-orag'
                      : 'none'
                  "
                  >{{
                    scope.row.lowSign > 0
                      ? "+" + scope.row.lowSign
                      : scope.row.lowSign < 0
                      ? "" + scope.row.lowSign
                      : ""
                  }}</i
                >
              </template>
            </el-table-column>
            <el-table-column prop="midNum" label="中风险" sortable>
              <template slot-scope="scope">
                <span class="num-box">{{ scope.row.midNum }}</span
                ><i
                  :class="
                    scope.row.midSign > 0
                      ? 'num-badge-blue'
                      : scope.row.midSign < 0
                      ? 'num-badge-orag'
                      : 'none'
                  "
                  >{{
                    scope.row.midSign > 0
                      ? "+" + scope.row.midSign
                      : scope.row.midSign < 0
                      ? "" + scope.row.midSign
                      : ""
                  }}</i
                >
              </template>
            </el-table-column>
            <el-table-column prop="highNum" label="高风险" sortable>
              <template slot-scope="scope">
                <span class="num-box">{{ scope.row.highNum }}</span
                ><i
                  :class="
                    scope.row.highSign > 0
                      ? 'num-badge-blue'
                      : scope.row.highSign < 0
                      ? 'num-badge-orag'
                      : 'none'
                  "
                  >{{
                    scope.row.highSign > 0
                      ? "+" + scope.row.highSign
                      : scope.row.highSign < 0
                      ? "" + scope.row.highSign
                      : ""
                  }}</i
                >
              </template>
            </el-table-column>
          </el-table>
          <el-table
              v-if="riskarning === 2"
              :data="tableDataAll"
              v-loading="loading1"
              element-loading-text="加载中..."
              element-loading-spinner="el-icon-loading"
              style="width: 100%"
              :row-key="rowKey"
              lazy
              :load="loadNextNode1"
              :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
              @sort-change="changeSort"
          >
            <el-table-column
                prop="regionName"
                label="地区"
                fixed
                :width="0.18 * $bodyWidth"
            >
            </el-table-column>
            <el-table-column prop="num" label="总计" sortable :width="0.21 * $bodyWidth">
              <template slot-scope="scope">
                <span class="num-box">{{ scope.row.num }}</span
                ><i
                  :class="
                    scope.row.numSign > 0
                      ? 'num-badge-blue'
                      : scope.row.numSign < 0
                      ? 'num-badge-orag'
                      : 'none'
                  "
              >{{
                  scope.row.numSign > 0
                      ? "+" + scope.row.numSign
                      : scope.row.numSign < 0
                      ? "" + scope.row.numSign
                      : ""
                }}</i
              >
              </template>
            </el-table-column>
            <el-table-column prop="credit3A" label="AAA" sortable :width="0.21 * $bodyWidth">
              <template slot-scope="scope">
                <span class="num-box">{{ scope.row.credit3A }}</span
                ><i
                  :class="
                    scope.row.credit3ASign > 0
                      ? 'num-badge-blue'
                      : scope.row.credit3ASign < 0
                      ? 'num-badge-orag'
                      : 'none'
                  "
              >{{
                  scope.row.credit3ASign > 0
                      ? "+" + scope.row.credit3ASign
                      : scope.row.credit3ASign < 0
                      ? "" + scope.row.credit3ASign
                      : ""
                }}</i
              >
              </template>
            </el-table-column>
            <el-table-column prop="credit2A" label="AA" sortable :width="0.21 * $bodyWidth">
              <template slot-scope="scope">
                <span class="num-box">{{ scope.row.credit2A }}</span
                ><i
                  :class="
                    scope.row.credit2ASign > 0
                      ? 'num-badge-blue'
                      : scope.row.credit2ASign < 0
                      ? 'num-badge-orag'
                      : 'none'
                  "
              >{{
                  scope.row.credit2ASign > 0
                      ? "+" + scope.row.credit2ASign
                      : scope.row.credit2ASign < 0
                      ? "" + scope.row.credit2ASign
                      : ""
                }}</i
              >
              </template>
            </el-table-column>
            <el-table-column prop="credit1A" label="A" sortable :width="0.21 * $bodyWidth">
              <template slot-scope="scope">
                <span class="num-box">{{ scope.row.credit1A }}</span
                ><i
                  :class="
                    scope.row.credit1ASign > 0
                      ? 'num-badge-blue'
                      : scope.row.credit1ASign < 0
                      ? 'num-badge-orag'
                      : 'none'
                  "
              >{{
                  scope.row.credit1ASign > 0
                      ? "+" + scope.row.credit1ASign
                      : scope.row.credit1ASign < 0
                      ? "" + scope.row.credit1ASign
                      : ""
                }}</i
              >
              </template>
            </el-table-column>
            <el-table-column prop="credit3B" label="BBB" sortable :width="0.21 * $bodyWidth">
              <template slot-scope="scope">
                <span class="num-box">{{ scope.row.credit3B }}</span
                ><i
                  :class="
                    scope.row.credit3BSign > 0
                      ? 'num-badge-blue'
                      : scope.row.credit3BSign < 0
                      ? 'num-badge-orag'
                      : 'none'
                  "
              >{{
                  scope.row.credit3BSign > 0
                      ? "+" + scope.row.credit3BSign
                      : scope.row.credit3BSign < 0
                      ? "" + scope.row.credit3BSign
                      : ""
                }}</i
              >
              </template>
            </el-table-column>
            <el-table-column prop="credit2B" label="BB" sortable :width="0.21 * $bodyWidth">
              <template slot-scope="scope">
                <span class="num-box">{{ scope.row.credit2B }}</span
                ><i
                  :class="
                    scope.row.credit2BSign > 0
                      ? 'num-badge-blue'
                      : scope.row.credit2BSign < 0
                      ? 'num-badge-orag'
                      : 'none'
                  "
              >{{
                  scope.row.credit2BSign > 0
                      ? "+" + scope.row.credit2BSign
                      : scope.row.credit2BSign < 0
                      ? "" + scope.row.credit2BSign
                      : ""
                }}</i
              >
              </template>
            </el-table-column>
            <el-table-column prop="credit1B" label="B" sortable :width="0.21 * $bodyWidth">
              <template slot-scope="scope">
                <span class="num-box">{{ scope.row.credit1B }}</span
                ><i
                  :class="
                    scope.row.credit1BSign > 0
                      ? 'num-badge-blue'
                      : scope.row.credit1BSign < 0
                      ? 'num-badge-orag'
                      : 'none'
                  "
              >{{
                  scope.row.credit1BSign > 0
                      ? "+" + scope.row.credit1BSign
                      : scope.row.credit1BSign < 0
                      ? "" + scope.row.credit1BSign
                      : ""
                }}</i
              >
              </template>
            </el-table-column>
            <el-table-column prop="credit3C" label="CCC" sortable :width="0.21 * $bodyWidth">
              <template slot-scope="scope">
                <span class="num-box">{{ scope.row.credit3C }}</span
                ><i
                  :class="
                    scope.row.credit3CSign > 0
                      ? 'num-badge-blue'
                      : scope.row.credit3CSign < 0
                      ? 'num-badge-orag'
                      : 'none'
                  "
              >{{
                  scope.row.credit3CSign > 0
                      ? "+" + scope.row.credit3CSign
                      : scope.row.credit3CSign < 0
                      ? "" + scope.row.credit3CSign
                      : ""
                }}</i
              >
              </template>
            </el-table-column>
            <el-table-column prop="credit2C" label="CC" sortable :width="0.21 * $bodyWidth">
              <template slot-scope="scope">
                <span class="num-box">{{ scope.row.credit2C }}</span
                ><i
                  :class="
                    scope.row.credit2CSign > 0
                      ? 'num-badge-blue'
                      : scope.row.credit2CSign < 0
                      ? 'num-badge-orag'
                      : 'none'
                  "
              >{{
                  scope.row.credit2CSign > 0
                      ? "+" + scope.row.credit2CSign
                      : scope.row.credit2CSign < 0
                      ? "" + scope.row.credit2CSign
                      : ""
                }}</i
              >
              </template>
            </el-table-column>
            <el-table-column prop="credit1C" label="C" sortable :width="0.21 * $bodyWidth">
              <template slot-scope="scope">
                <span class="num-box">{{ scope.row.credit1C }}</span
                ><i
                  :class="
                    scope.row.credit1CSign > 0
                      ? 'num-badge-blue'
                      : scope.row.credit1CSign < 0
                      ? 'num-badge-orag'
                      : 'none'
                  "
              >{{
                  scope.row.credit1CSign > 0
                      ? "+" + scope.row.credit1CSign
                      : scope.row.credit1CSign < 0
                      ? "" + scope.row.credit1CSign
                      : ""
                }}</i
              >
              </template>
            </el-table-column>
            <el-table-column prop="creditD" label="D" sortable :width="0.21 * $bodyWidth">
              <template slot-scope="scope">
                <span class="num-box">{{ scope.row.creditD }}</span
                ><i
                  :class="
                    scope.row.creditDSign > 0
                      ? 'num-badge-blue'
                      : scope.row.creditDSign < 0
                      ? 'num-badge-orag'
                      : 'none'
                  "
              >{{
                  scope.row.creditDSign > 0
                      ? "+" + scope.row.creditDSign
                      : scope.row.creditDSign < 0
                      ? "" + scope.row.creditDSign
                      : ""
                }}</i
              >
              </template>
            </el-table-column>
          </el-table>
          <el-table
              v-if="riskarning === 3"
              :data="tableDataAll"
              v-loading="loading1"
              element-loading-text="加载中..."
              element-loading-spinner="el-icon-loading"
              style="width: 100%"
              :row-key="rowKey"
              lazy
              :load="loadNextNode1"
              :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
              @sort-change="changeSort"
          >
            <el-table-column
                prop="regionName"
                label="地区"
                :width="0.18 * $bodyWidth"
                fixed
            >
            </el-table-column>
            <el-table-column prop="num" label="总计" sortable :width="0.21 * $bodyWidth">
              <template slot-scope="scope">
                <span class="num-box">{{ scope.row.num }}</span
                ><i
                  :class="
                    scope.row.numSign > 0
                      ? 'num-badge-blue'
                      : scope.row.numSign < 0
                      ? 'num-badge-orag'
                      : 'none'
                  "
              >{{
                  scope.row.numSign > 0
                      ? "+" + scope.row.numSign
                      : scope.row.numSign < 0
                      ? "" + scope.row.numSign
                      : ""
                }}</i
              >
              </template>
            </el-table-column>
            <el-table-column prop="warning1" label="一级预警" sortable :width="0.25 * $bodyWidth">
              <template slot-scope="scope">
                <span class="num-box">{{ scope.row.warning1 }}</span
                ><i
                  :class="
                    scope.row.warning1Sign > 0
                      ? 'num-badge-blue'
                      : scope.row.warning1Sign < 0
                      ? 'num-badge-orag'
                      : 'none'
                  "
              >{{
                  scope.row.warning1Sign > 0
                      ? "+" + scope.row.warning1Sign
                      : scope.row.numSign < 0
                      ? "" + scope.row.warning1Sign
                      : ""
                }}</i
              >
              </template>
            </el-table-column>
            <el-table-column prop="warning2" label="二级预警" sortable :width="0.25 * $bodyWidth">
              <template slot-scope="scope">
                <span class="num-box">{{ scope.row.warning2 }}</span
                ><i
                  :class="
                    scope.row.warning2Sign > 0
                      ? 'num-badge-blue'
                      : scope.row.warning2Sign < 0
                      ? 'num-badge-orag'
                      : 'none'
                  "
              >{{
                  scope.row.warning2Sign > 0
                      ? "+" + scope.row.warning2Sign
                      : scope.row.warning2Sign < 0
                      ? "" + scope.row.warning2Sign
                      : ""
                }}</i
              >
              </template>
            </el-table-column>
            <el-table-column prop="warning3" label="三级预警" sortable :width="0.25 * $bodyWidth">
              <template slot-scope="scope">
                <span class="num-box">{{ scope.row.warning3 }}</span
                ><i
                  :class="
                    scope.row.warning3Sign > 0
                      ? 'num-badge-blue'
                      : scope.row.warning3Sign < 0
                      ? 'num-badge-orag'
                      : 'none'
                  "
              >{{
                  scope.row.warning3Sign > 0
                      ? "+" + scope.row.warning3Sign
                      : scope.row.warning3Sign < 0
                      ? "" + scope.row.warning3Sign
                      : ""
                }}</i
              >
              </template>
            </el-table-column>
            <el-table-column prop="warning4" label="四级预警" sortable :width="0.25 * $bodyWidth">
              <template slot-scope="scope">
                <span class="num-box">{{ scope.row.warning4 }}</span
                ><i
                  :class="
                    scope.row.warning4Sign > 0
                      ? 'num-badge-blue'
                      : scope.row.warning4Sign < 0
                      ? 'num-badge-orag'
                      : 'none'
                  "
              >{{
                  scope.row.warning4Sign > 0
                      ? "+" + scope.row.warning4Sign
                      : scope.row.warning4Sign < 0
                      ? "" + scope.row.warning4Sign
                      : ""
                }}</i
              >
              </template>
            </el-table-column>
            <el-table-column prop="warning5" label="五级预警" sortable :width="0.25 * $bodyWidth">
              <template slot-scope="scope">
                <span class="num-box">{{ scope.row.warning5 }}</span
                ><i
                  :class="
                    scope.row.warning5Sign > 0
                      ? 'num-badge-blue'
                      : scope.row.warning5Sign < 0
                      ? 'num-badge-orag'
                      : 'none'
                  "
              >{{
                  scope.row.warning5Sign > 0
                      ? "+" + scope.row.warning5Sign
                      : scope.row.warning5Sign < 0
                      ? "" + scope.row.warning5Sign
                      : ""
                }}</i
              >
              </template>
            </el-table-column>
          </el-table>
          <div class="page-btn" v-if="tableData1.length > 10">
            <el-button type="text" @click="showAll = !showAll"
              >{{ showAll ? "收起全部" : "展开全部"
              }}<i
                class="el-icon-d-arrow-left el-icon--right"
                :class="showAll ? 'rotate-top' : 'rotate-bottom'"
              ></i
            ></el-button>
          </div>
        </div>
      </div>
      <!-- 风险预警按领域/行业统计 表格 -->
      <div class="idiot-two echarts">
        <div class="echarts-box">
          <div class="echarts-tit">
            <span>风险预警按领域/行业统计</span>
          </div>
          <KcSelect
            isBlue
            keyLabel="haripinType"
            :optionData="options"
            @popupVal="popupVal4"
          ></KcSelect>
        </div>
        <div class="element-tab">
          <el-table
            v-show="riskarning === 1"
            :data="tableDataAll2"
            v-loading="loading2"
            element-loading-text="加载中..."
            element-loading-spinner="el-icon-loading"
            style="width: 100%"
            row-key="industryId"
            lazy
            :load="loadNextNode2"
            :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
            @sort-change="changeSort2"
            :header-cell-style="{ textAlign: 'center' }"
          >
            <el-table-column
              prop="industryName"
              label="领域/行业"
              sortable
              :width="0.32 * $bodyWidth"
              fixed
            >
            </el-table-column>
            <el-table-column prop="num" label="总计" sortable>
              <template slot-scope="scope">
                <span class="num-box">{{ scope.row.num }}</span
                ><i
                  :class="
                    scope.row.numSign > 0
                      ? 'num-badge-blue'
                      : scope.row.numSign < 0
                      ? 'num-badge-orag'
                      : 'none'
                  "
                  >{{
                    scope.row.numSign > 0
                      ? "+" + scope.row.numSign
                      : scope.row.numSign < 0
                      ? "" + scope.row.numSign
                      : ""
                  }}</i
                >
              </template>
            </el-table-column>
            <el-table-column prop="lowNum" label="低风险" sortable>
              <template slot-scope="scope">
                <span class="num-box">{{ scope.row.lowNum }}</span
                ><i
                  :class="
                    scope.row.lowSign > 0
                      ? 'num-badge-blue'
                      : scope.row.lowSign < 0
                      ? 'num-badge-orag'
                      : 'none'
                  "
                  >{{
                    scope.row.lowSign > 0
                      ? "+" + scope.row.lowSign
                      : scope.row.lowSign < 0
                      ? "" + scope.row.lowSign
                      : ""
                  }}</i
                >
              </template>
            </el-table-column>
            <el-table-column prop="midNum" label="中风险" sortable>
              <template slot-scope="scope">
                <span class="num-box">{{ scope.row.midNum }}</span
                ><i
                  :class="
                    scope.row.midSign > 0
                      ? 'num-badge-blue'
                      : scope.row.midSign < 0
                      ? 'num-badge-orag'
                      : 'none'
                  "
                  >{{
                    scope.row.midSign > 0
                      ? "+" + scope.row.midSign
                      : scope.row.midSign < 0
                      ? "" + scope.row.midSign
                      : ""
                  }}</i
                >
              </template>
            </el-table-column>
            <el-table-column prop="highNum" label="高风险" sortable>
              <template slot-scope="scope">
                <span class="num-box">{{ scope.row.highNum }}</span
                ><i
                  :class="
                    scope.row.highSign > 0
                      ? 'num-badge-blue'
                      : scope.row.highSign < 0
                      ? 'num-badge-orag'
                      : 'none'
                  "
                  >{{
                    scope.row.highSign > 0
                      ? "+" + scope.row.highSign
                      : scope.row.highSign < 0
                      ? "" + scope.row.highSign
                      : ""
                  }}</i
                >
              </template>
            </el-table-column>
          </el-table>
          <el-table
              v-show="riskarning === 2"
              :data="tableDataAll2"
              v-loading="loading2"
              element-loading-text="加载中..."
              element-loading-spinner="el-icon-loading"
              style="width: 100%"
              row-key="industryId"
              lazy
              :load="loadNextNode2"
              :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
              @sort-change="changeSort2"
          >
            <el-table-column
                prop="industryName"
                label="领域/行业"
                sortable
                fixed
                :width="0.32 * $bodyWidth"
            >
            </el-table-column>
            <el-table-column prop="num" label="总计" sortable :width="0.21 * $bodyWidth">
              <template slot-scope="scope">
                <span class="num-box">{{ scope.row.num }}</span
                ><i
                  :class="
                    scope.row.numSign > 0
                      ? 'num-badge-blue'
                      : scope.row.numSign < 0
                      ? 'num-badge-orag'
                      : 'none'
                  "
              >{{
                  scope.row.numSign > 0
                      ? "+" + scope.row.numSign
                      : scope.row.numSign < 0
                      ? "" + scope.row.numSign
                      : ""
                }}</i
              >
              </template>
            </el-table-column>
            <el-table-column prop="credit3A" label="AAA" sortable :width="0.21 * $bodyWidth">
              <template slot-scope="scope">
                <span class="num-box">{{ scope.row.credit3A }}</span
                ><i
                  :class="
                    scope.row.credit3ASign > 0
                      ? 'num-badge-blue'
                      : scope.row.credit3ASign < 0
                      ? 'num-badge-orag'
                      : 'none'
                  "
              >{{
                  scope.row.credit3ASign > 0
                      ? "+" + scope.row.credit3ASign
                      : scope.row.credit3ASign < 0
                      ? "" + scope.row.credit3ASign
                      : ""
                }}</i
              >
              </template>
            </el-table-column>
            <el-table-column prop="credit2A" label="AA" sortable :width="0.21 * $bodyWidth">
              <template slot-scope="scope">
                <span class="num-box">{{ scope.row.credit2A }}</span
                ><i
                  :class="
                    scope.row.credit2ASign > 0
                      ? 'num-badge-blue'
                      : scope.row.credit2ASign < 0
                      ? 'num-badge-orag'
                      : 'none'
                  "
              >{{
                  scope.row.credit2ASign > 0
                      ? "+" + scope.row.credit2ASign
                      : scope.row.credit2ASign < 0
                      ? "" + scope.row.credit2ASign
                      : ""
                }}</i
              >
              </template>
            </el-table-column>
            <el-table-column prop="credit1A" label="A" sortable :width="0.21 * $bodyWidth">
              <template slot-scope="scope">
                <span class="num-box">{{ scope.row.credit1A }}</span
                ><i
                  :class="
                    scope.row.credit1ASign > 0
                      ? 'num-badge-blue'
                      : scope.row.credit1ASign < 0
                      ? 'num-badge-orag'
                      : 'none'
                  "
              >{{
                  scope.row.credit1ASign > 0
                      ? "+" + scope.row.credit1ASign
                      : scope.row.credit1ASign < 0
                      ? "" + scope.row.credit1ASign
                      : ""
                }}</i
              >
              </template>
            </el-table-column>
            <el-table-column prop="credit3B" label="BBB" sortable :width="0.21 * $bodyWidth">
              <template slot-scope="scope">
                <span class="num-box">{{ scope.row.credit3B }}</span
                ><i
                  :class="
                    scope.row.credit3BSign > 0
                      ? 'num-badge-blue'
                      : scope.row.credit3BSign < 0
                      ? 'num-badge-orag'
                      : 'none'
                  "
              >{{
                  scope.row.credit3BSign > 0
                      ? "+" + scope.row.credit3BSign
                      : scope.row.credit3BSign < 0
                      ? "" + scope.row.credit3BSign
                      : ""
                }}</i
              >
              </template>
            </el-table-column>
            <el-table-column prop="credit2B" label="BB" sortable :width="0.21 * $bodyWidth">
              <template slot-scope="scope">
                <span class="num-box">{{ scope.row.credit2B }}</span
                ><i
                  :class="
                    scope.row.credit2BSign > 0
                      ? 'num-badge-blue'
                      : scope.row.credit2BSign < 0
                      ? 'num-badge-orag'
                      : 'none'
                  "
              >{{
                  scope.row.credit2BSign > 0
                      ? "+" + scope.row.credit2BSign
                      : scope.row.credit2BSign < 0
                      ? "" + scope.row.credit2BSign
                      : ""
                }}</i
              >
              </template>
            </el-table-column>
            <el-table-column prop="credit1B" label="B" sortable :width="0.21 * $bodyWidth">
              <template slot-scope="scope">
                <span class="num-box">{{ scope.row.credit1B }}</span
                ><i
                  :class="
                    scope.row.credit1BSign > 0
                      ? 'num-badge-blue'
                      : scope.row.credit1BSign < 0
                      ? 'num-badge-orag'
                      : 'none'
                  "
              >{{
                  scope.row.credit1BSign > 0
                      ? "+" + scope.row.credit1BSign
                      : scope.row.credit1BSign < 0
                      ? "" + scope.row.credit1BSign
                      : ""
                }}</i
              >
              </template>
            </el-table-column>
            <el-table-column prop="credit3C" label="CCC" sortable :width="0.21 * $bodyWidth">
              <template slot-scope="scope">
                <span class="num-box">{{ scope.row.credit3C }}</span
                ><i
                  :class="
                    scope.row.credit3CSign > 0
                      ? 'num-badge-blue'
                      : scope.row.credit3CSign < 0
                      ? 'num-badge-orag'
                      : 'none'
                  "
              >{{
                  scope.row.credit3CSign > 0
                      ? "+" + scope.row.credit3CSign
                      : scope.row.credit3CSign < 0
                      ? "" + scope.row.credit3CSign
                      : ""
                }}</i
              >
              </template>
            </el-table-column>
            <el-table-column prop="credit2C" label="CC" sortable :width="0.21 * $bodyWidth">
              <template slot-scope="scope">
                <span class="num-box">{{ scope.row.credit2C }}</span
                ><i
                  :class="
                    scope.row.credit2CSign > 0
                      ? 'num-badge-blue'
                      : scope.row.credit2CSign < 0
                      ? 'num-badge-orag'
                      : 'none'
                  "
              >{{
                  scope.row.credit2CSign > 0
                      ? "+" + scope.row.credit2CSign
                      : scope.row.credit2CSign < 0
                      ? "" + scope.row.credit2CSign
                      : ""
                }}</i
              >
              </template>
            </el-table-column>
            <el-table-column prop="credit1C" label="C" sortable :width="0.21 * $bodyWidth">
              <template slot-scope="scope">
                <span class="num-box">{{ scope.row.credit1C }}</span
                ><i
                  :class="
                    scope.row.credit1CSign > 0
                      ? 'num-badge-blue'
                      : scope.row.credit1CSign < 0
                      ? 'num-badge-orag'
                      : 'none'
                  "
              >{{
                  scope.row.credit1CSign > 0
                      ? "+" + scope.row.credit1CSign
                      : scope.row.credit1CSign < 0
                      ? "" + scope.row.credit1CSign
                      : ""
                }}</i
              >
              </template>
            </el-table-column>
            <el-table-column prop="creditD" label="D" sortable :width="0.21 * $bodyWidth">
              <template slot-scope="scope">
                <span class="num-box">{{ scope.row.creditD }}</span
                ><i
                  :class="
                    scope.row.creditDSign > 0
                      ? 'num-badge-blue'
                      : scope.row.creditDSign < 0
                      ? 'num-badge-orag'
                      : 'none'
                  "
              >{{
                  scope.row.creditDSign > 0
                      ? "+" + scope.row.creditDSign
                      : scope.row.creditDSign < 0
                      ? "" + scope.row.creditDSign
                      : ""
                }}</i
              >
              </template>
            </el-table-column>
          </el-table>
          <el-table
              v-show="riskarning === 3"
              :data="tableDataAll2"
              v-loading="loading2"
              element-loading-text="加载中..."
              element-loading-spinner="el-icon-loading"
              style="width: 100%"
              row-key="industryId"
              lazy
              :load="loadNextNode2"
              :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
              @sort-change="changeSort2"
          >
            <el-table-column
                prop="industryName"
                label="领域/行业"
                sortable
                :width="0.32 * $bodyWidth"
                fixed
            >
            </el-table-column>
            <el-table-column prop="num" label="总计" sortable :width="0.21 * $bodyWidth">
              <template slot-scope="scope">
                <span class="num-box">{{ scope.row.num }}</span
                ><i
                  :class="
                    scope.row.numSign > 0
                      ? 'num-badge-blue'
                      : scope.row.numSign < 0
                      ? 'num-badge-orag'
                      : 'none'
                  "
              >{{
                  scope.row.numSign > 0
                      ? "+" + scope.row.numSign
                      : scope.row.numSign < 0
                      ? "" + scope.row.numSign
                      : ""
                }}</i
              >
              </template>
            </el-table-column>
            <el-table-column prop="warning1" label="一级预警" sortable :width="0.25 * $bodyWidth">
              <template slot-scope="scope">
                <span class="num-box">{{ scope.row.warning1 }}</span
                ><i
                  :class="
                    scope.row.warning1Sign > 0
                      ? 'num-badge-blue'
                      : scope.row.warning1Sign < 0
                      ? 'num-badge-orag'
                      : 'none'
                  "
              >{{
                  scope.row.warning1Sign > 0
                      ? "+" + scope.row.warning1Sign
                      : scope.row.numSign < 0
                      ? "" + scope.row.warning1Sign
                      : ""
                }}</i
              >
              </template>
            </el-table-column>
            <el-table-column prop="warning2" label="二级预警" sortable :width="0.25 * $bodyWidth">
              <template slot-scope="scope">
                <span class="num-box">{{ scope.row.warning2 }}</span
                ><i
                  :class="
                    scope.row.warning2Sign > 0
                      ? 'num-badge-blue'
                      : scope.row.warning2Sign < 0
                      ? 'num-badge-orag'
                      : 'none'
                  "
              >{{
                  scope.row.warning2Sign > 0
                      ? "+" + scope.row.warning2Sign
                      : scope.row.warning2Sign < 0
                      ? "" + scope.row.warning2Sign
                      : ""
                }}</i
              >
              </template>
            </el-table-column>
            <el-table-column prop="warning3" label="三级预警" sortable :width="0.25 * $bodyWidth">
              <template slot-scope="scope">
                <span class="num-box">{{ scope.row.warning3 }}</span
                ><i
                  :class="
                    scope.row.warning3Sign > 0
                      ? 'num-badge-blue'
                      : scope.row.warning3Sign < 0
                      ? 'num-badge-orag'
                      : 'none'
                  "
              >{{
                  scope.row.warning3Sign > 0
                      ? "+" + scope.row.warning3Sign
                      : scope.row.warning3Sign < 0
                      ? "" + scope.row.warning3Sign
                      : ""
                }}</i
              >
              </template>
            </el-table-column>
            <el-table-column prop="warning4" label="四级预警" sortable :width="0.25 * $bodyWidth">
              <template slot-scope="scope">
                <span class="num-box">{{ scope.row.warning4 }}</span
                ><i
                  :class="
                    scope.row.warning4Sign > 0
                      ? 'num-badge-blue'
                      : scope.row.warning4Sign < 0
                      ? 'num-badge-orag'
                      : 'none'
                  "
              >{{
                  scope.row.warning4Sign > 0
                      ? "+" + scope.row.warning4Sign
                      : scope.row.warning4Sign < 0
                      ? "" + scope.row.warning4Sign
                      : ""
                }}</i
              >
              </template>
            </el-table-column>
            <el-table-column prop="warning5" label="五级预警" sortable :width="0.25 * $bodyWidth">
              <template slot-scope="scope">
                <span class="num-box">{{ scope.row.warning5 }}</span
                ><i
                  :class="
                    scope.row.warning5Sign > 0
                      ? 'num-badge-blue'
                      : scope.row.warning5Sign < 0
                      ? 'num-badge-orag'
                      : 'none'
                  "
              >{{
                  scope.row.warning5Sign > 0
                      ? "+" + scope.row.warning5Sign
                      : scope.row.warning5Sign < 0
                      ? "" + scope.row.warning5Sign
                      : ""
                }}</i
              >
              </template>
            </el-table-column>
          </el-table>
          <div class="page-btn" v-if="tableData2.length > 10">
            <el-button type="text" @click="showAll2 = !showAll2"
              >{{ showAll2 ? "收起全部" : "展开全部"
              }}<i
                class="el-icon-d-arrow-left el-icon--right"
                :class="showAll2 ? 'rotate-top' : 'rotate-bottom'"
              ></i
            ></el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
import LineChart from "@/components/charts/line"; // 四码折线图
import Pmap from "@/components/charts/map"; //地图
import Baidumap from "@/components/baidumap/index.vue";
import {
  regionalRiskInfo,
  riskStatistics,
  riskAdd,
  riskCumulative,
  riskCityStatistics,
  riskIndustryStatistics,
  cerditStatistics,
  regionalCreditInfo,
  earlyWarningStatistics,
  creditCityStatistics,
  warningCityStatistics,
  creditIndustryStatistics,
  warningIndustryStatistics,
  cerditAdd,
  warningAdd,
  creditCumulative,
  warningCumulative,
  regionalEarlyWarningInfo,
} from "@/api/homepolice";
import { cardType } from "@/api/common";
import Toptab from "@/components/toptab.vue";
export default {
  name: "HomePolice",
  props: {
    regionName: [String],
    areaName: [String],
    cityName: [String],
    flag: [String, Number],
    regionId: [String, Number],
    regionType: [String, Number],
  },
  data() {
    return {
      lineChart1: false,
      lineChart2: false,
      riskarningList: ['','风险状况','信用状况','风险预警'],
      riskarning: 1,
      riskarningName: '风险状况',
      hairpinType1: "",
      hairpinType2: "",
      options: [],
      radio1: "1",
      radio2: "1",
      dataregion: [],
      radioData1: [
        {
          name: "近一周",
          value: "1",
        },
        {
          name: "近一月",
          value: "2",
        },
        {
          name: "近半年",
          value: "3",
        },
        {
          name: "全部",
          value: "4",
        },
      ],
      lineChartData1: {
        xAxisData: [],
        seriesData: [
          {
            name: "高风险",
            data: [],
          },
          {
            name: "中风险",
            data: [],
          },
          {
            name: "低风险",
            data: [],
          },
        ],
      },
      lineChartData2: {
        xAxisData: [],
        seriesData: [
          {
            name: "高风险",
            data: [],
          },
          {
            name: "中风险",
            data: [],
          },
          {
            name: "低风险",
            data: [],
          },
        ],
      },
      showAll: false,
      loading1: false,
      loading2: false,
      tableDataAll: [],
      tableData1: [],
      showAll2: false,
      tableDataAll2: [],
      tableData2: [],
      mapOptions: [],
      baidumapOptions: [],
      showmap: true,
      showbmap: true,
      forCodeData: [],
      cerditStatistic: [],
      fiveCodeData: [],
      lineChartLegend1: ["低风险", "中风险", "高风险"],
      lineChartLegend2: ["AAA","AA","A","BBB","BB","B","CCC","CC","C","D",],
      lineChartLegend3: [
        "一级预警",
        "二级预警",
        "三级预警",
        "四级预警",
        "五级预警",
      ],
      lineChartColor1: ["#b64020", "#b5ac2c", "#b4b9c1"],
      lineChartColor2: [
        "#487fef",
        "#73f6bc",
        "#6d56ee",
        "#d85dc5",
        "#f2f9b2",
        "#d43284",
        "#de3227",
        "#d27455",
        "#e1749e",
        "#72f7fd",
      ],
      lineChartColor3: ["#de3227", "#d43385", "#d95ec6", "#d27555", "#f2fab2"],
    };
  },
  filters: {
    numFilter(value) {
      // 截取当前数据到小数点后两位
      let realVal = parseFloat(value).toFixed(2);
      return realVal;
    },
  },
  computed: {
    costRequest() {
      return {
        regionName: this.regionName,
        areaName: this.areaName,
        cityName: this.cityName,
        regionId: this.regionId,
        regionType: this.regionType,
        flag: this.flag,
      };
    },
  },
  watch: {
    costRequest: {
      handler(val, oldVal) {
        this.intData();
      },
      deep: true, //增加deep 观察对象的子对象变化
    },
    radio1: function (val) {
      this.riskAdd();
    },
    radio2: function (val) {
      this.riskCumulative();
    },
    hairpinType1: function (val) {
      this.riskCityStatistics();
    },
    hairpinType2: function (val) {
      this.riskIndustryStatistics();
    },
    showAll: function (val) {
      this.showAllData();
    },
    showAll2: function (val) {
      this.showAllData2();
    },
    riskarning: function (val) {
      this.riskarningName = this.riskarningList[val];
      this.intData();
    },
  },
  mounted() {
    this.intData();
  },
  methods: {
    popupVal(val, row) {
      this.radio1 = val;
    },
    popupVal2(val, row) {
      this.radio2 = val;
    },
    popupVal3(val) {
      this.hairpinType2 = val;
    },
    popupVal4(val) {
      this.hairpinType2 = val;
    },

    intData() {
      this.cardType();
      this.riskAdd();
      this.riskCumulative();
      this.riskCityStatistics();
      this.riskIndustryStatistics();
      if (this.riskarning === 1) {
        this.riskStatistics();
        this.getMapOptionData();
      } else if (this.riskarning === 2) {
        this.cerditStatistics();
        this.getMapOptionData2();
      } else if (this.riskarning === 3) {
        this.earlyWarningStatistics();
        this.getMapOptionData3();
      }
    },
    cardType() {
      cardType().then((res) => {
        res.data.unshift({ name: "全部", haripinType: "" });
        this.options = res.data;
      });
    },
    getMapOptionData() {
      regionalRiskInfo({
        regionId: this.regionId,
        regionType: this.regionType,
        flag: this.flag,
      })
          .then((res) => {
            //console.log('风险预警区域分布情况:',res)
            //this.mapOptions = res.data;
            let data = [];
            res.data.forEach((item) => {
              data.push({
                name: item.regionName,
                value: item.num,
                data: item.data,
              });
            });
            this.mapOptions = data;
            if (this.regionType >= 3 && this.areaName) {
              this.baidumapOptions = data;
            }

            // res.data.forEach((item,i) => {
            //   this.mapOptions.push({
            //     name:item.regionName,
            //     value:item.num,
            //     data:item.data
            //   })
            // });
          })
          .catch((err) => {
            console.log(err);
          });
    },
    getMapOptionData2() {
      this.mapOptions = [];
      this.showbmap = true;
      this.showmap = true;
      regionalCreditInfo({
        regionId: this.regionId,
        regionType: this.regionType,
        flag: this.flag,
      })
          .then((res) => {
            // console.log("商户区域分布情况:", res);
            //this.mapOptions = res.data;

            let data = [];
            res.data.forEach((item) => {
              data.push({
                name: item.regionName,
                value: item.num,
                data: item.creditData,
              });
            });
            this.mapOptions = data;
            if (this.regionType >= 3 && this.areaName) {
              this.baidumapOptions = data;
            }

            // console.log(this.mapOptions, "ditu");
          })
          .catch((err) => {
            console.log(err);
          });
    },
    getMapOptionData3() {
      this.showbmap = true;
      this.showmap = true;
      regionalEarlyWarningInfo({
        regionId: this.regionId,
        regionType: this.regionType,
        flag: this.flag,
      })
        .then((res) => {
          //console.log('风险预警区域分布情况:',res)
          //this.mapOptions = res.data;
          let data = [];
          res.data.forEach((item) => {
            data.push({
              name: item.regionName,
              value: item.num,
              data: item.earlyWarningData,
            });
          });
          this.mapOptions = data;
          this.showmap = false;
          if (this.regionType >= 3 && this.areaName) {
            this.baidumapOptions = data;
            this.showbmap = false;
          }

          // res.data.forEach((item,i) => {
          //   this.mapOptions.push({
          //     name:item.regionName,
          //     value:item.num,
          //     data:item.data
          //   })
          // });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    riskStatistics() {
      riskStatistics({
        regionId: this.regionId,
        regionType: this.regionType,
        flag: this.flag,
      })
        .then((res) => {
          let topdata = [];
          let data = [
            {
              name: "较昨日",
              data: [],
            },
            {
              name: "较上周",
              data: [],
            },
            {
              name: "较上月",
              data: [],
            },
            {
              name: "较上年",
              data: [],
            },
          ];
          res.data.map((v, i) => {
            data[0].data.push(v.yesterdayNum);
            data[1].data.push(v.weekNum);
            data[2].data.push(v.monthNum);
            data[3].data.push(v.yearNum);
            topdata.push({
              val: v.num,
              com: "家",
              name:
                v.risk == 1
                  ? "低风险"
                  : v.risk == 2
                  ? "中风险"
                  : v.risk == 3
                  ? "高风险"
                  : "",
            });
          });
          this.dataregion = data;
          this.forCodeData = topdata;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    cerditStatistics() {
      cerditStatistics({
        regionId: this.regionId,
        regionType: this.regionType,
        flag: this.flag,
      })
        .then((res) => {
          let topdata = [];
          let data = [
            {
              name: "较昨日",
              data: [],
            },
            {
              name: "较上周",
              data: [],
            },
            {
              name: "较上月",
              data: [],
            },
            {
              name: "较上年",
              data: [],
            },
          ];
          res.data.map((v, i) => {
            data[0].data.push(v.yesterdayNum);
            data[1].data.push(v.weekNum);
            data[2].data.push(v.monthNum);
            data[3].data.push(v.yearNum);
            topdata.push({
              val: v.num,
              com: "家",
              name: v.riskLevel,
            });
          });
          this.dataregion = data;
          this.cerditStatistic = topdata;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    earlyWarningStatistics() {
      earlyWarningStatistics({
        regionId: this.regionId,
        regionType: this.regionType,
        flag: this.flag,
      })
        .then((res) => {
          let topdata = [];
          let data = [
            {
              name: "较昨日",
              data: [],
            },
            {
              name: "较上周",
              data: [],
            },
            {
              name: "较上月",
              data: [],
            },
            {
              name: "较上年",
              data: [],
            },
          ];
          res.data.map((v, i) => {
            data[0].data.push(v.yesterdayNum);
            data[1].data.push(v.weekNum);
            data[2].data.push(v.monthNum);
            data[3].data.push(v.yearNum);
            topdata.push({
              val: v.num,
              com: "家",
              name: v.riskLevel,
            });
          });
          this.dataregion = data;
          this.fiveCodeData = topdata;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    riskAdd() {
      this.lineChartData1 = {
        xAxisData: [],
        seriesData: [],
      };
      this.lineChart1 = true;
      console.log(this.riskarning);
      if (this.riskarning === 1) {
        riskAdd({
          regionId: this.regionId,
          regionType: this.regionType,
          flag: this.flag,
          type: this.radio1,
        })
            .then((res) => {
              this.lineChart1 = false;
              //console.log('风险商户新增变化趋势',res)
              if (res.data != null) {
                this.lineChartData1 = {
                  xAxisData: res.data.x_axis,
                  seriesData: [
                    {
                      name: "高风险",
                      data: res.data.highs,
                      color: this.lineChartColor1[0],
                    },
                    {
                      name: "中风险",
                      data: res.data.mids,
                      color: this.lineChartColor1[1],
                    },
                    {
                      name: "低风险",
                      data: res.data.lows,
                      color: this.lineChartColor1[2],
                    },
                  ],
                };
              } else {
                this.lineChartData1 = {
                  xAxisData: [],
                  seriesData: this.lineChartLegend1.map((v) => {
                    return {
                      name: v,
                      data: [],
                    };
                  }),
                };
              }
            })
            .catch((err) => {
              console.log(err);
            });
      } else if (this.riskarning === 2) {
        cerditAdd({
          regionId: this.regionId,
          regionType: this.regionType,
          flag: this.flag,
          type: this.radio1,
        })
            .then((res) => {
              this.lineChart1 = false;
              //console.log('风险商户新增变化趋势',res)
              if (res.data != null) {
                this.lineChartData1 = {
                  xAxisData: res.data.x_axis,
                  seriesData: [
                    {
                      name: "AAA",
                      data: res.data.numCredit3As,
                      color: this.lineChartColor2[0],
                    },
                    {
                      name: "AA",
                      data: res.data.numCredit2As,
                      color: this.lineChartColor2[1],
                    },
                    {
                      name: "A",
                      data: res.data.numCredit1As,
                      color: this.lineChartColor2[2],
                    },
                    {
                      name: "BBB",
                      data: res.data.numCredit3Bs,
                      color: this.lineChartColor2[3],
                    },
                    {
                      name: "BB",
                      data: res.data.numCredit2Bs,
                      color: this.lineChartColor2[4],
                    },
                    {
                      name: "B",
                      data: res.data.numCredit1Bs,
                      color: this.lineChartColor2[5],
                    },
                    {
                      name: "CCC",
                      data: res.data.numCredit3Cs,
                      color: this.lineChartColor2[6],
                    },
                    {
                      name: "CC",
                      data: res.data.numCredit2Cs,
                      color: this.lineChartColor2[7],
                    },
                    {
                      name: "C",
                      data: res.data.numCredit1Cs,
                      color: this.lineChartColor2[8],
                    },
                    {
                      name: "D",
                      data: res.data.numCreditDs,
                      color: this.lineChartColor2[9],
                    },
                  ],
                };
              } else {
                this.lineChartData1 = {
                  xAxisData: [],
                  seriesData: this.lineChartLegend2.map((v) => {
                    return {
                      name: v,
                      data: [],
                    };
                  }),
                };
              }
            })
            .catch((err) => {
              console.log(err);
            });
      } else if (this.riskarning === 3) {
        warningAdd({
          regionId: this.regionId,
          regionType: this.regionType,
          flag: this.flag,
          type: this.radio1,
        })
            .then((res) => {
              this.lineChart1 = false;
              //console.log('风险商户新增变化趋势',res)
              if (res.data != null) {
                this.lineChartData1 = {
                  xAxisData: res.data.x_axis,
                  seriesData: [
                    {
                      name: "一级预警",
                      data: res.data.numWarning1s,
                      color: this.lineChartColor3[0],
                    },
                    {
                      name: "二级预警",
                      data: res.data.numWarning2s,
                      color: this.lineChartColor3[1],
                    },
                    {
                      name: "三级预警",
                      data: res.data.numWarning3s,
                      color: this.lineChartColor3[2],
                    },
                    {
                      name: "四级预警",
                      data: res.data.numWarning4s,
                      color: this.lineChartColor3[3],
                    },
                    {
                      name: "五级预警",
                      data: res.data.numWarning5s,
                      color: this.lineChartColor3[4],
                    },
                  ],
                };
              } else {
                this.lineChartData1 = {
                  xAxisData: [],
                  seriesData: this.lineChartLegend3.map((v) => {
                    return {
                      name: v,
                      data: [],
                    };
                  }),
                };
              }
            })
            .catch((err) => {
              console.log(err);
            });
      }
    },
    riskCumulative() {
      this.lineChartData2 = {
        xAxisData: [],
        seriesData: [],
      };
      this.lineChart2 = true;
      if (this.riskarning === 1) {
        riskCumulative({
          regionId: this.regionId,
          regionType: this.regionType,
          flag: this.flag,
          type: this.radio2,
        })
            .then((res) => {
              this.lineChart2 = false;
              //console.log('风险商户累计变化趋势',res)
              if (res.data != null) {
                this.lineChartData2 = {
                  xAxisData: res.data.x_axis,
                  seriesData: [
                    {
                      name: "高风险",
                      data: res.data.highs,
                      color: this.lineChartColor1[0],
                    },
                    {
                      name: "中风险",
                      data: res.data.mids,
                      color: this.lineChartColor1[1],
                    },
                    {
                      name: "低风险",
                      data: res.data.lows,
                      color: this.lineChartColor1[2],
                    },
                  ],
                };
              } else {
                this.lineChartData2 = {
                  xAxisData: [],
                  seriesData: this.lineChartLegend1.map((v) => {
                    return {
                      name: v,
                      data: [],
                    };
                  }),
                };
              }
            })
            .catch((err) => {
              console.log(err);
            });
      } else if (this.riskarning === 2) {
        creditCumulative({
          regionId: this.regionId,
          regionType: this.regionType,
          flag: this.flag,
          type:this.radio2
        }).then(res=>{
          this.lineChart2 = false;
          //console.log('风险商户新增变化趋势',res)
          if(res.data!=null){
            this.lineChartData2={
              xAxisData: res.data.x_axis,
              seriesData: [
                {
                  name: 'AAA',
                  data:res.data.numCredit3As,
                  color: this.lineChartColor2[0],
                },{
                  name: 'AA',
                  data:res.data.numCredit2As,
                  color: this.lineChartColor2[1],
                },{
                  name: 'A',
                  data:res.data.numCredit1As,
                  color: this.lineChartColor2[2],
                },{
                  name: 'BBB',
                  data:res.data.numCredit3Bs,
                  color: this.lineChartColor2[3],
                },{
                  name: 'BB',
                  data:res.data.numCredit2Bs,
                  color: this.lineChartColor2[4],
                },{
                  name: 'B',
                  data:res.data.numCredit1Bs,
                  color: this.lineChartColor2[5],
                },{
                  name: 'CCC',
                  data:res.data.numCredit3Cs,
                  color: this.lineChartColor2[6],
                },{
                  name: 'CC',
                  data:res.data.numCredit2Cs,
                  color: this.lineChartColor2[7],
                },{
                  name: 'C',
                  data:res.data.numCredit1Cs,
                  color: this.lineChartColor2[8],
                },{
                  name: 'D',
                  data:res.data.numCreditDs,
                  color: this.lineChartColor2[9],
                }
              ]
            }
          }else {
            this.lineChartData2 = {
              xAxisData: [],
              seriesData: this.lineChartLegend2.map(v => {
                return {
                  name: v,
                  data: []
                }
              })
            }
          }
        }).catch((err) => {
          console.log(err);
        });
      } else if (this.riskarning === 3) {
        warningCumulative({
          regionId: this.regionId,
          regionType: this.regionType,
          flag: this.flag,
          type:this.radio2
        }).then(res=>{
          this.lineChart2 = false;
          //console.log('风险商户新增变化趋势',res)
          if(res.data!=null){
            this.lineChartData2={
              xAxisData: res.data.x_axis,
              seriesData: [
                {
                  name: '一级预警',
                  data:res.data.numWarning1s,
                  color: this.lineChartColor3[0],
                },{
                  name: '二级预警',
                  data:res.data.numWarning2s,
                  color: this.lineChartColor3[1],
                },{
                  name: '三级预警',
                  data:res.data.numWarning3s,
                  color: this.lineChartColor3[2],
                },{
                  name: '四级预警',
                  data:res.data.numWarning4s,
                  color: this.lineChartColor3[3],
                },{
                  name: '五级预警',
                  data:res.data.numWarning5s,
                  color: this.lineChartColor3[4],
                }
              ]
            }
          }else{
            this.lineChartData2={
              xAxisData: [],
              seriesData: this.lineChartLegend3.map(v =>{
                return {
                  name:v,
                  data:[]
                }
              })
            }
          }
        }).catch(err =>{
          console.log(err)
        })
      }
    },
    riskCityStatistics() {
      this.tableDataAll = [];
      this.loading1 = true;
      if (this.riskarning === 1) {
        riskCityStatistics({
          hairpinType: this.hairpinType1,
          regionId: this.regionId,
          regionType: this.regionType,
          flag: this.flag,
        }).then((res) => {
          this.tableDataAll = [];
          this.loading1 = false;
          this.$nextTick(()=>{
            this.tableData1 = res.data;
            this.tableDataAll = this.tableData1.slice(0, 10);
            this.showAllData();
          });
        });
      } else if (this.riskarning === 2) {
        creditCityStatistics({
          hairpinType: this.hairpinType1,
          regionId: this.regionId,
          regionType: this.regionType,
          flag: this.flag,
        }).then((res) => {
          this.tableDataAll = [];
          this.loading1 = false;
          this.$nextTick(()=>{
            this.tableData1 = res.data;
            this.tableDataAll = this.tableData1.slice(0, 10);
            this.showAllData();
          });
        });
      } else if (this.riskarning === 3) {
        warningCityStatistics({
          hairpinType: this.hairpinType1,
          regionId: this.regionId,
          regionType: this.regionType,
          flag: this.flag,
        }).then((res) => {
          this.loading1 = false;
          this.tableDataAll = [];
          this.$nextTick(() => {
            this.tableData1 = res.data;
            this.tableDataAll = this.tableData1.slice(0, 10);
            this.showAllData();
          });
        });
      }
    },
    showAllData() {
      if (!this.showAll) {
        this.tableDataAll = this.tableData1.slice(0, 10);
      } else {
        this.tableDataAll = this.tableData1;
      }
    },
    //确保row-key不重复
    rowKey(row) {
      return row.regionName + row.regionId;
    },
    loadNextNode1(tree, treeNode, resolve) {
      //console.log(tree, treeNode, resolve)
      if (this.riskarning === 1) {
        riskCityStatistics({
          hairpinType: this.hairpinType1,
          regionId: tree.regionId,
          regionType: tree.regionType,
          flag: tree.regionType,
        }).then((res) => {
          resolve(res.data || []);
        });
      } else if (this.riskarning === 2) {
        creditCityStatistics({
          hairpinType: this.hairpinType1,
          regionId: tree.regionId,
          regionType: tree.regionType,
          flag: tree.regionType,
        }).then((res) => {
          resolve(res.data || []);
        });
      } else if (this.riskarning === 3) {
        warningCityStatistics({
          hairpinType: this.hairpinType1,
          regionId: tree.regionId,
          regionType: tree.regionType,
          flag: tree.regionType,
        }).then((res) => {
          resolve(res.data || []);
        });
      }
    },
    riskIndustryStatistics() {
      this.tableDataAll2 = [];
      this.loading2 = true;
      if (this.riskarning === 1) {
        riskIndustryStatistics({
          hairpinType: this.hairpinType2,
          regionId: this.regionId,
          regionType: this.regionType,
          flag: this.flag,
        }).then((res) => {
          //console.log('风险商户按领域/行业统计',res)
          res.data.forEach((item) => {
            item.hasChildren = true;
          });
          this.loading2 = false;
          this.tableDataAll2 = [];
          this.$nextTick(() => {
            this.tableData2 = res.data;
            this.tableDataAll2 = this.tableData2.slice(0, 10);
            this.showAllData2();
          });
        });
      } else if (this.riskarning === 2) {
        creditIndustryStatistics({
          hairpinType: this.hairpinType2,
          regionId: this.regionId,
          regionType: this.regionType,
          flag: this.flag,
        }).then((res) => {
          //console.log('风险商户按领域/行业统计',res)
          res.data.forEach((item) => {
            item.hasChildren = true;
          });
          this.loading2 = false;
          this.tableDataAll2 = [];
          this.$nextTick(() => {
            this.tableData2 = res.data;
            this.tableDataAll2 = this.tableData2.slice(0, 10);
            this.showAllData2();
          });
        });
      } else if (this.riskarning === 3) {
        warningIndustryStatistics({
          hairpinType: this.hairpinType2,
          regionId: this.regionId,
          regionType: this.regionType,
          flag: this.flag,
        }).then((res) => {
          //console.log('风险商户按领域/行业统计',res)
          res.data.forEach((item) => {
            item.hasChildren = true;
          });
          this.loading2 = false;
          this.tableDataAll2 = [];
          this.$nextTick(() => {
            this.tableData2 = res.data;
            this.tableDataAll2 = this.tableData2.slice(0, 10);
            this.showAllData2();
          });
        });
      }
    },
    showAllData2() {
      if (!this.showAll2) {
        this.tableDataAll2 = this.tableData2.slice(0, 10);
      } else {
        this.tableDataAll2 = this.tableData2;
      }
    },
    loadNextNode2(tree, treeNode, resolve) {
      //console.log(tree, treeNode, resolve)
      if (this.riskarning === 1) {
        riskIndustryStatistics({
          hairpinType: this.hairpinType2,
          industryId: tree.industryId,
          regionId: this.regionId,
          regionType: this.regionType,
          flag: this.flag,
        }).then((res) => {
          resolve(res.data);
        });
      } else if (this.riskarning === 2) {
        creditIndustryStatistics({
          hairpinType: this.hairpinType2,
          industryId: tree.industryId,
          regionId: this.regionId,
          regionType: this.regionType,
          flag: this.flag,
        }).then((res) => {
          resolve(res.data);
        });
      } else if (this.riskarning === 3) {
        warningIndustryStatistics({
          hairpinType: this.hairpinType2,
          industryId: tree.industryId,
          regionId: this.regionId,
          regionType: this.regionType,
          flag: this.flag,
        }).then((res) => {
          resolve(res.data);
        });
      }
    },
    changeSort(column, prop, order) {
      this.tableDataAll = this.tableData1;
      this.showAll = true;
    },
    changeSort2(column, prop, order) {
      this.tableDataAll2 = this.tableData2;
      this.showAll2 = true;
    },
  },
  components: {
    LineChart,
    Pmap,
    Baidumap,
    Toptab,
  },
};
</script>

<style lang="scss" scoped >
.HomePolice {
  .tab-box {
    display: flex;
    justify-content: center;
    background: #ffffff;
    padding: 50px 30px 30px;
    .tab-btns {
      width: 100%;
      &::v-deep .el-radio-button {
        .el-radio-button__inner {
          width: 230px;
          height: 56px;
          font-size: 24px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        &:first-child .el-radio-button__inner {
          border-radius: 10px 0 0 10px;
        }
        &:last-child .el-radio-button__inner {
          border-radius: 0 10px 10px 0;
        }
      }
    }
  }


  .pie-box {
    margin-bottom: 30px;
  }
}
</style>
